module.exports = (loading, DefaultIndex) => [
  {
    ...loading('userWallet'),
    meta: {
      title: '账户汇总',
    },
  },
  {
    ...loading('orderAmountDetail'),
    meta: {
      title: '订单明细',
    },
  },
  {
    ...loading('orderProfitDetail'),
    meta: {
      title: '利润明细',
    },
  },
   {
    ...loading('userWalletLog'),
    meta: {
      title: '用户流水',
    },
  },
 {
   ...loading('userWalletDatail'),
   meta: {
     title: '账户明细',
   },
 },
  {
    ...loading('supplierGoodsStatistics'),
    meta: {
      title: '销售报表',
    },
  },
    {
        ...loading('supplierReconciliation'),
        meta: {
            title: '供应商对账',
        },
    },
    {
        ...loading('gosProfit'),
        meta: {
            title: '拼团利润',
        },
    },
  {
   ...loading('withdraws'),
    meta: {
      title: '提现列表',
    },
  },
  {
    ...loading('orderProfit'),
    meta: {
      title: '订单利润',
    },
  },
  {
    ...loading('balanceRecord'),
    meta: {
      title: '余额记录',
    },
  },
  {
    ...loading('prompt'),
    meta: {
      title: '提现提示',
    },
  },

];
