module.exports = (loading, defaultIndex) => [
  {
    ...defaultIndex('', [
      loading('index'),
      loading('_id'),
    ]),
    meta: {
      title: '用户管理',
    },
  },
  {
    ...defaultIndex('userProtocol', [
      loading('index', 'userProtocol'),
	  loading('userProtocolTemplate', 'userProtocol'),
	  loading('showProtocol', 'userProtocol'),
      // loading('_id', 'userProtocol'),
    ]),
    meta: {
      title: '用户协议',
    },
  },

];
