module.exports = (loading, defaultIndex) => [
  {
    ...defaultIndex('', [
      loading('index'),
      loading('_id'),
    ]),
    meta: {
      title: '会务课程',
    },
  },
  {
    ...loading('confCourseCategory'),
    meta: {
      title: '课程分类',
    },
  },
  {
    ...loading('confCourseUser'),
    meta: {
      title: '课程用户',
    },
  },
  {
    ...loading('couponUse'),
    meta: {
      title: '电子券使用记录',
    },
  },
  {
    ...loading('courseVerification'),
    meta: {
      title: '课程核销记录',
    },
  },
  {
    ...loading('courseOfflineRecord'),
    meta: {
      title: '线下课程登记',
    },
  },
];
